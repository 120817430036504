import SearchFormHandlerBase from './search-form-handler-base.mjs';
import { isNullOrWhiteSpace } from '../../utils/string-utils.mjs';
import AutocompleteHandler from './freetext-autocomplete-combobox.mjs';

export default class SwedishSearchFormHandler extends SearchFormHandlerBase {
  /**
   * @type {AutocompleteHandler}
   */
  #autocompleteHandler;

  #geographicalFilterContainer = null;
  #geographicalFilterTemplate = null;

  constructor(containerElement, autocompleteHandler) {
    super(containerElement);

    if(!autocompleteHandler) {
      throw new Error('autocompleteHandler must be provided.');
    }

    this.#autocompleteHandler = autocompleteHandler;

    this.#geographicalFilterContainer = containerElement.querySelector('#geographical-filters');
    this.#geographicalFilterTemplate = containerElement.querySelector('#geographical-filter-template');

    this.#init();
  }

  #init() {
    this.#autocompleteHandler.addEventListener('add-filter', event => {
      this.#addGeographicalFilter(event.detail.field, event.detail.label, event.detail.value);
    });

    this.#geographicalFilterContainer.addEventListener('change', this.#handleGeographialFilterRemoval.bind(this));
  }

  #addGeographicalFilter(field, labelValue, value) {
    const existingFilters = [...this.#geographicalFilterContainer.querySelectorAll(`input[name="${field}"]`)];
    if(existingFilters.length > 0) {
      const newValueAlreadyExists = existingFilters.some(filter => filter.value === value);
      if(newValueAlreadyExists) {
        return;
      }
    }

    const container = this.#geographicalFilterContainer;

    const templateRoot = this.#geographicalFilterTemplate.content.cloneNode(true);

    const inputValue = templateRoot.querySelector('input[type="checkbox"]');
    inputValue.name = field;
    inputValue.value = value;

    const inputLabel = templateRoot.querySelector('input[type="hidden"]');
    inputLabel.name = `${field}-${value}-label`;
    inputLabel.value = labelValue;

    const span = templateRoot.querySelector('span');
    span.innerText = labelValue;

    container.appendChild(templateRoot);

    this.handleFormChange();
  }

  #handleGeographialFilterRemoval(event) {
    const checkbox = event.target;
    if(checkbox.type !== 'checkbox') {
      return;
    }

    // This shouldn't really happen, but just in case...
    if(checkbox.checked) {
      return;
    }

    if(checkbox.name === 'sold') {
      const comingSoonSelect = document.getElementById('coming-soon');
      if(!!comingSoonSelect) {
        comingSoonSelect.disabled = false;
      }
    }

    checkbox.parentElement.parentElement.remove();
  }

  getUrlParts(formData) {
    return [
      this.getEstateTypeForUrl(formData),
      this.#getMunicipalityForUrl(formData),
      this.#getAreaForUrl(formData)
    ];
  }

  #getMunicipalityForUrl(/** @type {FormData} */ formData) {
    const municipalityId = formData.get('municipalityId');

    if(isNullOrWhiteSpace(municipalityId)) {
      return null;
    }

    return this.getCleanedUrlPart(formData.get(`municipalityId-${municipalityId}-label`));
  }

  #getAreaForUrl(/** @type {FormData} */ formData) {
    const areaId = formData.get('areaId');

    if(isNullOrWhiteSpace(areaId)) {
      return null;
    }

    return this.getCleanedUrlPart(formData.get(`areaId-${areaId}-label`));
  }
}
